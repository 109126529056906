/* eslint-disable @typescript-eslint/naming-convention */
export default {
  translations: {
    languagesPage: {
      title: 'Idiomas'
    },
    benefits: {
      yourBenefits: 'Tus beneficios'
    },
    flashMessage: {
      emptyBag: 'Tu bolso está vacío.'
    },
    suggestion: {
      advantageAndOrderToo: 'Disfruta y ordena también'
    },
    about: {
      aboutStore: 'Sobre la tienda',
      openTwentyFour: 'Abierto 24 horas'
    },
    languages: {
      english: 'Inglés',
      spanish: 'Español',
      portuguese: 'Portugués'
    },
    errors: {
      orderError04: 'Algo salió mal al enviar el orden, inténtalo de nuevo! (Error #04)'
    },
    profilePage: {
      title: 'Sus pedidos más rápidos y seguros',
      description: 'Inicie sesión y guarde sus datos para éste y futuros pedidos.'
    },
    offline: {
      youAreNotConnected: 'Parece que estas desconectado',
      checkYourConnection: 'Revisa tu modo avión o internet y vuelve a intentarlo'
    },
    option: {
      biggestValue: 'mayor valor',
      mediumValue: 'promedio de valores',
      priceIsCalculatedBy: 'El precio se calcula mediante la opción de '
    },
    detail: {
      productDetail: 'Detalle del producto',
      suggestionForYou: 'Sugerencia para ti',
      underEighteen: 'Producto no destinado a menores de 18 años.'
    },
    shortWeekdays: {
      sunday: 'dom',
      monday: 'lun',
      friday: 'vie',
      tuesday: 'mar',
      saturday: 'sab',
      thursday: 'jue',
      wednesday: 'mie'
    },
    product: {
      startingAt: 'Desde',
      card: 'Tarjeta de producto',
      currentlyUnavailable: 'El producto seleccionado no está disponible para la venta en este momento.'
    },
    fullAge: {
      over18: '¿Más de 18 años?',
      declare: 'Declaro que soy mayor de 18 años',
      prohibitedForMinors: 'Este orden contiene artículos prohibidos para menores.'
    },
    alert: {
      error: 'Icono de error',
      success: 'Icono de éxito',
      info: 'Icono de información',
      warning: 'Icono de advertencia',
      default: 'Icono predeterminado'
    },
    weekdays: {
      monday: 'Lunes',
      sunday: 'Domingo',
      tuesday: 'Martes',
      friday: 'Viernes',
      thursday: 'Jueves',
      saturday: 'Sábado',
      wednesday: 'Miércoles'
    },
    authPage: {
      title: 'Iniciar sesión o registrarse',
      description:
        '¡Registrarse facilita sus pedidos y puede usarse en cualquier restaurante con Delivery o QR Code a través de Goomer!'
    },
    maintenance: {
      weBackSoon: '¡Volveremos pronto!',
      inMaintenance: 'Estamos en mantenimiento',
      toImproveYourExperience: 'Para mejorar aún más tu experiencia, ¡estamos ajustando algunas cosas aquí!'
    },
    paymentPromoModal: {
      news: '¡Noticias!',
      payBillFaster: 'Paga tu factura más rápido',
      everyoneAtTheTable:
        'Usted y todos en la mesa podrán pagar la cuenta al mismo tiempo en sus celulares. Es rápido y fácil.'
    },
    map: {
      confirmLocation: 'Confirmar ubicación',
      confirmLocationOnMap: 'Confirma tu ubicación en el mapa',
      errorToGetAddressInfo:
        'Se produjo un error al obtener información para su dirección. Por favor inténtalo de nuevo.'
    },
    appearancePage: {
      title: 'Apariencia',
      lightTheme: 'Tema claro',
      darkTheme: 'Tema oscuro',
      deviceConfig: 'Configuración del dispositivo',
      deviceConfigDescription: 'La aplicación utilizará la misma configuración que tu teléfono celular.'
    },
    fee: {
      fee: 'Tarifa',
      minute: 'min',
      free: 'Gratis',
      toBeAgreed: 'A convenir',
      localPickUp: 'Recogida en el sitio',
      subjectToAvailability: 'Sujeto a disponibilidad',
      subjectToAvailabilityAndFees: '$t(fee.subjectToAvailability) y tarifas'
    },
    optionals: {
      chooseMaxOption: 'Elija {{max}} opción',
      chooseMaxOptions: 'Elija {{max}} opciones',
      chooseUptoMaxOption: 'Elija hasta {{max}} opción',
      chooseUptoMaxOptions: 'Elija hasta {{max}} opciones',
      chooseBetweenMinAndMax: 'Elija entre {{min}} y {{max}} opciones'
    },
    waiter: {
      tryAgain: 'Intentar otra vez',
      callToWaiter: 'Llamar al camarero',
      waiterCalled: 'Llamaron a un camarero',
      whatYouNeed: 'Háganos saber lo que necesita',
      backToYouInTime: 'Él te ayudará en poco tiempo',
      errorToCallWaiter: 'Se produjo un error al llamar al camarero'
    },
    pwaModal: {
      addInHomeScreen: 'Agregar pantalla de inicio',
      addOurApp: 'Agrega nuestra aplicación a tu celular.',
      bannerTitle: 'Tenga nuestra aplicación en la palma de la mano.',
      bannerMessage: 'Haz clic aquí y guárdala en tu pantalla de inicio.',
      noDownloadNeeded:
        '<strong>No necesitas descargar nada, </strong> simplemente agrega nuestro restaurante a la pantalla de inicio de tu teléfono celular y ordena más rápido la próxima vez.'
    },
    terms: {
      termsAccept: '¡Leo y acepto!',
      termsAction: 'continuar con el registro',
      termsTitle: 'Términos y política de uso',
      termsAgreement: 'Al {{action}}, acepta los',
      goomerAndRestaurant: 'de Goomer y del restaurante.',
      serviceAndUsageTerms: 'Términos de servicio y la Política de uso de datos'
    },
    user: {
      change: 'Cambiar usuario',
      phoneNumber: 'Tu número de celular',
      contactInfo: 'Información del contacto',
      inputNamePlaceholder: '¿Cómo te llamaremos?',
      phoneInfo:
        'El número de celular se utilizará para informarte sobre el estado de tu orden, además de identificarte para agilizar futuros pedidos.'
    },
    securityCardCodeModal: {
      securityCode: 'Código de seguridad',
      confirmAndSend: 'Confirmar y enviar',
      insertSecurityCode:
        'Introduzca el código de seguridad de 3 o 4 dígitos (CVV) escrito en el reverso de la tarjeta',
      insertCodeAgain:
        'Por razones de seguridad, le solicitamos que vuelva a ingresar el código de seguridad ubicado en el reverso de su tarjeta.'
    },
    navigation: {
      more: 'Más',
      menu: 'Menú',
      bill: 'Factura',
      search: 'Buscar',
      order: 'Pedidos',
      signIn: 'Entrar',
      profile: 'Perfil',
      waiter: 'Camarero',
      goToWhatsapp: 'Ir a WhatsApp',
      returnToMenu: 'Volver al menú',
      returnToMyTab: 'Volver a mi cuenta',
      goToNubankApp: 'Vaya a la aplicación Nubank'
    },
    label: {
      addSignal: 'Signo más',
      subtractSignal: 'Signo menos',
      imageOf: 'Imagen de {{name}}',
      storeImage: 'Imagen de la tienda',
      radioHour: 'Elige la opción {{label}}',
      productImage: 'Imagen del producto {{name}}',
      highlightAction: 'Acción de elemento destacado.',
      securityCodeModalTitle: 'Código de seguridad del título'
    },
    floatingFooter: {
      currentlyClosed: '¡Cerrado por el momento!',
      unavailableProduct: '¡Producto no disponible!',
      notAcceptingNewOrders: 'Actualmente no aceptamos nuevos pedidos.',
      quantityLimitOnProduct: 'Has alcanzado el número máximo de artículos por pedido',
      unavailableDueToTheTime: 'El producto no está disponible actualmente debido al día/hora.'
    },
    errorPage: {
      ohNo: '¡¡AHHH NO!!',
      wrongLink: 'El enlace esta mal',
      whatHappened: '¿Qué pudo haber pasado?',
      pageNotFound: 'No encontramos la pagina.',
      noProducts: 'No hay menús con productos registrados',
      errorToLoadPage: 'Hubo un error al cargar la página.',
      deliveryDisabled: 'La Solución de Delivery está deshabilitada en el restaurante.'
    },
    cardEntry: {
      protectedArea: 'Área protegida',
      newCard: 'Nueva tarjeta de credito',
      debit: '$t(payment.debit, lowercase)',
      credit: '$t(payment.credit, lowercase)',
      safePayment: '$t(general.payment) seguro con:',
      editCard: 'Editar tarjeta de {{formattedCardType}}',
      invalidData: 'Datos no válidos. Verifique los datos ingresados ​​y vuelva a intentarlo.'
    },
    splash: {
      seeMenu: 'Ver menú',
      seeProducts: 'Ver productos',
      accessMenu: 'Accede al menú',
      placeAnOrder: 'Realizar orden',
      orderByDelivery: 'Ordenar por delivery',
      orderByTakeaway: 'Solicite recogida local',
      orderInRestaurant: 'Orden en el restaurante',
      logInOrRegister: 'Iniciar sesión o registrarse',
      loginTitle: 'Inicie sesión y ordene más rápido'
    },
    search: {
      searchInMenu: 'Busca en el menú',
      searchProducts: 'Buscar productos',
      recentSearches: 'Búsquedas recientes',
      productNotFound: 'Producto no encontrado',
      seeAllProducts: 'Ver todos los productos',
      anyResults: 'No encontramos ningún resultado al buscar "{{term}}"',
      productsAndCategoriesFound: 'Encontramos {{productsQuantity}} producto(s) y {{categoriesQuantity}} categoría(s).'
    },
    paymentReceiptModal: {
      voucher: 'Prueba',
      reviewData: 'Revisar datos',
      backToMyAccount: 'Volver a mi cuenta',
      availableInYourAccount: ' También estará disponible cuando regrese a su cuenta. ',
      mainReasons: 'Vea los principales motivos que pueden haber provocado un error en su pago',
      paymentReceipt: 'Este es el comprobante de tu pago.{{text}}Si es necesario, preséntalo a un asistente al salir.'
    },
    ratingPage: {
      title: 'Evaluar',
      sendRating: 'Enviar reseña',
      subtitle: '¡Tu opinión es muy importante!',
      iconAriaLabel: 'Icono de revisión de {{item}}',
      successMessage: 'Revisión enviada exitosamente',
      errorMessage: 'Se produjo un error al enviar su reseña',
      successDescription: 'Tu respuesta es muy importante para nosotros',
      errorDescription: 'Verifique la información que ingresó y vuelva a intentarlo'
    },
    possibleStatus: {
      makeOrder: 'Hacer un pedido',
      chooseName: 'Elija un nombre',
      reReadQrCode: 'Vuelva a leer el QR Code',
      choosePaymentMethod: 'Elegir forma de pago',
      insertYourDocument: 'Introduzca su CPF/CNPJ',
      chooseScheduleDate: 'Elija una fecha de cita',
      tableNumber: 'Introduzca el número de la mesa',
      deliveryAddress: 'Elija su dirección de entrega',
      contactNumber: 'Introduzca su número de contacto'
    },
    receiptInfo: {
      total: 'Total de la',
      totalPaid: 'Total pagado',
      progressBar: 'Barra de progreso',
      accountSummary: 'Resumen de cuenta',
      paymentMadeOn: 'Pago realizado en:',
      paymentNotMade: 'Pago no realizado',
      successPayment: '¡Pago realizado con éxito!',
      paymentErrorAnimation: 'Animación de error de pago',
      paymentSuccessAnimation: 'Animación de pago exitoso',
      paymentStatusAnimation: 'Animación del estado del pago'
    },
    callToAction: {
      logInAndSaveYourData: '¡Inicia sesión y guarda tus datos para tu próxima compra!',
      logInToLoyaltyProgram: '¡Inicia sesión para unirte al <strong>Programa de Fidelización!</strong>',
      logInAndGetFreeDelivery:
        '¡Entra y disfruta de <strong>entrega gratuita en pedidos superiores a {{minimumValue}}!</strong>',
      logInAndGetFirstOrderDiscount:
        '¡Participa y recibe <strong>{{percentageDiscount}} de descuento</strong> en tu primer orden en tienda!'
    },
    qrCodeScanner: {
      notAllowed: 'Permiso rechazado',
      trackStart: 'Dispositivo ocupado',
      notReadable: 'Dispositivo ocupado',
      notFound: 'No se encontraron dispositivos',
      devicesNotFound: 'No se encontraron dispositivos',
      errorAccessingCamera: 'Error al acceder a la cámara',
      allowAccessToCamera: 'Debes permitir el acceso a la cámara para continuar',
      cameraError: 'Se ha producido un error con la cámara. Por favor, inténtelo de nuevo.',
      aimYourPhoneCamera: 'Apunta la cámara de tu celular <br /> al <strong>QR Code de {{qrCodeMode}}</strong>'
    },
    clubPage: {
      informData: 'proporcionar mis datos',
      createSubscription: 'Crear Registro',
      subscribeToClub: 'Regístrate en el Club de Ventajas',
      subscriptionSuccess: '¡Registro completado exitosamente!',
      fillForm: 'Complete los campos a continuación para registrarse y utilizar el Club en su tableta.',
      clubRegisterError:
        'Se produjo un error al registrarse en el Club de Ventajas. Intente iniciar la transmisión nuevamente en su tableta o hable con una persona de soporte.'
    },
    howItWorks: {
      mediumValue: 'Valor medio',
      biggestValue: 'Mayor valor',
      biggestValueDescription:
        'El precio final considerará el <strong>valor más alto</strong> entre las opciones que elijas.',
      mediumValueDescription:
        'El precio final considerará los <strong>valores medios</strong> entre las opciones que elijas.',
      howItWorksExample:
        'Ejemplo: Si eliges la opción A (R$ {{firstValue}}) y la opción B (R$ {{ secondValue}}), <strong>el precio final será R$ {{finalValue}}.</strong >'
    },
    morePage: {
      languages: {
        title: '$t(languagesPage.title)',
        description: 'Elige el idioma que prefieras'
      },
      about: {
        title: 'Sobre nosotros',
        description: 'Descubre la historia de nuestro restaurante'
      },
      appearance: {
        title: '$t(appearancePage.title)',
        description: 'Elige entre oscuro, claro o automático'
      },
      rating: {
        title: '$t(ratingPage.title) tu experiencia',
        description: 'Deja un comentario sobre tu experiencia con nosotros'
      }
    },
    login: {
      continueWithEmail: 'Continuar con e-mail',
      continueWithGoogle: 'Continuar con Google',
      loginSuccess: 'Iniciar sesión exitosamente',
      select: 'Seleccione una opción para continuar',
      registrationCompleted: '¡Registro completado!',
      continueWithoutLogin: 'Continuar sin iniciar sesión',
      googleError: 'Se produjo un error al iniciar sesión a través de Google. Por favor inténtalo de nuevo.',
      info: 'Puede utilizar su inicio de sesión en cualquier restaurante que utilice Delivery o QR Code de Goomer. ¡Disfrútalo!'
    },
    scheduling: {
      to: 'a',
      scheduling: 'Programación',
      remove: '¿Eliminar horario?',
      confirmTime: 'Confirmar horario',
      scheduleDelivery: 'Programar envío',
      scheduleOrder: 'Programación de pedidos',
      scheduleLocal: 'Programar recogida local',
      approximateTime: 'Los plazos de entrega son aproximados',
      closedMessage: '¡Actualmente estamos cerrados, pero aún puedes programar tu orden!',
      nowYouCanScheduleYourOrder:
        'Ahora su pedido se puede programar a través de la aplicación. Recibe en casa o recoge en el día y hora elegido por ti.'
    },
    couponMessage: {
      useInOnsiteOrder: 'uso para pedidos in balcón',
      couponWasExpired: 'El cupón {{infoCode}} ha caducado',
      invalidCouponWeekday: 'Cupón no válido para este día de la semana',
      couponDisabledByStore: 'Este cupón ha sido desactivado por la tienda!',
      couponOnlyValidFor: 'Cupón válido únicamente para {{formattedMessage}}',
      couponNotFound: 'Cupón no encontrado. Compruebe el código e introdúzcalo de nuevo.',
      couponValidBetween: 'Cupón válido únicamente entre el {{initDate}} u el {{finishDate}}',
      couponNeedOrderValueOver: 'Para activar el cupón, es necesario tener un pedido superior a {{orderValue}}'
    },
    discount: {
      applied: 'Aplicado',
      applyCoupon: 'Aplicar cupón',
      couponCode: 'Código de cupón',
      insertCoupon: 'Insertar cupón',
      couponApplied: 'Cupón aplicado',
      deleteCoupon: '¿Eliminar cupón?',
      applyingCoupon: 'Aplicando cupón',
      discountCoupon: 'Cupón de descuento',
      loyaltyDiscount: 'Descuento de fidelidad',
      couponAriaLabel: 'Área para insertar cupón',
      percentApplied: '{{value}}% de descuento{{text}}!',
      discountApplied: '{{value}} de descuento{{text}}!',
      discountMaxValue: 'Este descuento se aplica hasta un valor máximo de {{value}} del total',
      enterCouponDescription: 'Ingrese el código de cupón a continuación para validar su descuento'
    },
    menu: {
      ofDiscount: 'de descuento',
      hasMinimumValue: 'para pedidos superiores a {{minValue}}',
      validInLoyaltyProgram: '¡Pedido válido en el Programa de Fidelidad!',
      firstOrderInStore: '¿Primer pedido en tienda? <strong>{{errorMessage}}</strong>',
      missingValueInLoyaltyProgram: 'Quedan <strong>{{value}}</strong> para participar del Fidelidad',
      youHasDiscount: '<strong>{{name}} • </strong> Tienes <strong>{{discountText}} de descuento </strong>',
      youHasDiscountInFirstOrder: ' ¡Tienes <strong>{{couponValue}}</strong> para usar en tu primer pedido!',
      youEarnedDiscount: '<strong>Fidelidad:</strong> ¡obtuviste {{discountValue}} de descuento para usar en tu pedido!'
    },
    firstOrder: {
      registerNow: 'Regístrate ahora',
      storeFirstOrder: '¿Primer orden en tienda?',
      firstOrderDiscount: 'Descuento por Primer Orden',
      firstOrderRules: 'Abrir reglas de descuento de primer orden',
      validForOrders: 'Válido para pedidos de al menos {{minimumValue}}.',
      registerToGetDiscount: '¡Regístrese y reciba {{discountPercentage}} de descuento!',
      discountInFinalOrder: 'El descuento se aplicará automáticamente al completar el orden.',
      getFirstOrderDiscount: 'Obtén {{discountPercentage}} de descuento en tu primer orden en ',
      shouldBeLoggedIn: 'Para utilizar el descuento del primer orden, el usuario debe iniciar sesión.',
      firstOrderDiscountNotCumulative:
        'El descuento del primer orden no se puede combinar con otros descuentos o cupones.'
    },
    localOrder: {
      myOrder: 'Mi pedido',
      lastName: 'y apellido',
      ofClient: 'del cliente',
      tableNumber: 'Número de mesa',
      howYouWillPay: '¿Cómo pagarás?',
      makingOrder: 'Realizando el pedido',
      howToCallYou: '¿Cómo te llamaremos?',
      whichIsYourTable: '¿Cuál es tu mesa?',
      localOrderTitle: 'Nombre {{clientName}}',
      orderOf: 'Order de la {{modeAndNumber}}',
      ifWeNeedTalkToYou: 'si necesitamos hablar contigo',
      enterClientName: 'Introduzca el nombre del cliente',
      orderWithYourCurrentType: '¿Quieres realizar el pedido en el {{orderType}} actual?',
      waiterToLocateYourTable: 'Esta información es útil para que el camarero ubique su mesa al momento de la entrega.',
      errorToGetCartData:
        'Se produjo un error al obtener los datos de su carrito. Escanea el código QR y vuelve a intentarlo.'
    },
    loggedArea: {
      cards: 'Mis tarjetas',
      personalData: 'Mi perfil',
      default: 'Área registrada',
      account: 'Cuenta y acceso',
      addresses: 'Mis direcciones',
      back: 'Volver al área registrada',
      notifications: 'Notificaciones por e-mail',
      cardsDescription: 'Edite o actualice sus tarjetas',
      errorMessage: 'Error al guardar. ¡Intentar otra vez!',
      successMessage: '¡La información cambió exitosamente!',
      personalDataDescription: 'Edite o actualice sus datos',
      addressesDescription: 'Edite o actualice sus direcciones',
      notificationsDescription: 'Reciba notificaciones directamente en su correo electrónico',
      message: 'Tu registro es válido en cualquier restaurante con Delivery o QR Code de Goomer.',
      notificationsExplanation:
        'Me gustaría recibir e-mails de los restaurantes donde he orden con ofertas, novedades y otras comunicaciones.'
    },
    feedback: {
      newOrder: 'Nuevo Orden',
      seeMyOrder: 'Ver mi orden',
      seeMyOrders: 'Ver mis pedidos',
      unavailableMenu: 'Menú no disponible',
      soonInYourTable: 'Pronto estará en tu mesa.',
      orderCompleted: '¡Pedido completado con éxito!',
      qrcodeInactive: 'Upss! El QR Code no está activo',
      orderToKitchenSent: '¡Pedido enviado a la cocina!',
      contactEstablishment: 'Contactar con el establecimiento',
      sentOrderToKitchen: '¡Pedido enviado a cocina con éxito!',
      callAnAttendant: 'Llama a un asistente para que te oriente',
      newOrderInOrderMode: 'Nuevo orden en {{orderMode}} {{number}}',
      orderNotSent: 'Upss! Hubo un problema y el orden no fue enviado',
      notPaidForYourOrder: 'Si no has pagado tu orden, dirígete al cajero y espera tu orden.',
      checkYourConnection: 'Verifique su conexión y si el problema continúa, llame a un asistente.',
      toSeeOrderDetails: 'Para ver los detalles del orden, acceda a su Administrador de Pedidos en el panel de Goomer'
    },
    accountVerification: {
      toEmail: 'al e-mail',
      resendCode: 'Reenviar código',
      checkCode: 'Código {{check}}',
      almostThere: '¡Ya casi estamos!',
      resendNewCode: 'Reenviar código en:',
      invalidCode: 'no válido. ¡Intentar otra vez!',
      logInOrRegister: 'Iniciar sesión o registrarse',
      insertSentCode: 'Ingrese el código que fue enviado a',
      insertYourEmail: 'Ingrese su e-mail abajo para continuar',
      otherRegister: 'a otro $t(general.subscription, lowercase)',
      completeYourSignUp: 'Completa tu $t(general.subscription, lowercase)',
      signUpInformation: 'Estos datos nos ayudan a adaptar la aplicación a sus necesidades y preferencias.',
      customerPhoneNumberInfo: 'El número de celular se utilizará para informarle sobre el estado de su orden.',
      sendCodeErrorMessage: 'Se produjo un error al enviar el código por e-mail. Por favor inténtalo de nuevo.',
      googleLoginErrorMessage:
        'Se produjo un error al iniciar sesión a través de Google. Por favor inténtalo de nuevo.',
      phoneAlreadyRegistered:
        'Este teléfono ya está vinculado {{validation}}. Ingrese otro número o regrese e inicie sesión.'
    },
    information: {
      tapOn: 'Toca ',
      local: 'Lugar',
      settings: 'Ajustes',
      safariSetting: 'aA',
      active: 'Activa la ',
      localization: 'Ubicación',
      turnOnThe: 'Activar parte de ',
      websiteAdjusts: 'Ajustes del sitio web',
      localizationDisabled: 'Ubicación deshabilitada',
      websiteConfiguration: 'Configuración del sitio web',
      makeAnOrder: 'Ahora solo haz tus pedidos. ¡Disfrutar!',
      nextToTheWebsite: ' junto a la dirección del sitio web en Safari.',
      scanQrcode: 'Escanea el código en la mesa o mostrador del restaurante.',
      activateLocalization: '¡Activa la ubicación en la configuración de tu navegador!',
      openSmartphoneCamera: 'Abra la cámara de su teléfono o la aplicación de QR Code.',
      canOrderWithGoomer: 'Ahora puedes ordenar con Goomer directamente en el restaurante.',
      tapOnThreeDots: 'A la derecha de la barra de enlaces, toque el ícono de tres puntos al lado',
      stepsBelowToActivate:
        'Siga los pasos a continuación para activarlo. Lo necesitamos para encontrar el restaurante más cercano a ti.'
    },
    loyalty: {
      hour: 'hora',
      hours: 'horas',
      completeUntil: 'Complete antes del',
      loyaltyProgram: 'Programa de Fidelización',
      andParticipateOf: '$t(authPage.title) y participar en el',
      loyaltyProgramConcluded: '¡Programa de Fidelización completado!',
      orderAndEarn: 'Pedir {{programOrders}} veces y oreja {{discountValue}}!',
      discountEarned: 'Tienes un descuento de {{discountValue}}, ¡disfrútalo!',
      validOrdersBy: 'Los pedidos realizados por {{channelNames}} son válidos.',
      onlyValidOrders: 'En el Programa sólo se cuentan los pedidos válidos y completados.',
      orderWillBeCountedInLoyaltyProgram: 'Su pedido se contará en el Programa de Lealtad.',
      scoreWillBeConfirmedAfterBill: 'La puntuación se confirmará una vez cerrada la cuenta.',
      useLoyaltyDiscount: 'Para utilizar el descuento Fidelidade, el usuario debe estar registrado.',
      scoreWillBeConfirmedAfterOrder: 'La puntuación se confirmará una vez que el restaurante complete el pedido.',
      discountNotCumulative:
        'El descuento del Programa de Fidelización no se puede combinar con otros descuentos o cupones.',
      purchasesValidInProgram:
        'Para que las compras sean válidas en el Programa, el usuario debe iniciar sesión en el momento del pago.',
      missingOrderValueToLoyaltyProgram:
        'Quedan <strong>{{remainingValue}}</strong> para que tu pedido sea válido en el Programa de Lealtad.',
      nextOrderCanBeMadeAt:
        '<strong>Lealtad:</strong> Su próximo pedido válido para el Programa de Lealtad se puede realizar en {{countdown}}.',
      autoDiscount:
        'El descuento se aplicará automáticamente a la compra una vez finalizado el Programa y se podrá utilizar en un plazo de {{days}} días.',
      onlyValidOrdersInIntervalHours:
        'Los pedidos válidos del Programa de Fidelización deben realizarse con {{orderIntervalHours}} {{hours}} de diferencia.'
    },
    address: {
      state: 'UF',
      city: 'Ciudad',
      address: 'Calle',
      delivery: 'Entrega',
      neighborhood: 'Barrio',
      zipcode: 'Código postal',
      complement: 'Complemento',
      noAddress: 'Ninguna dirección',
      addAddress: 'Agregar dirección',
      saveAddress: 'Guardar dirección',
      feeToBeAgreed: 'Tarifa a convenir',
      complementPlaceholder: 'Casa, piso',
      cityNotFound: 'Ciudad no encontrada',
      referencePoint: 'Punto de referencia',
      approximateDelivery: 'Entrega aprox.',
      confirmAddress: 'Confirmar dirección',
      deliveryAddress: 'Dirección de entrega',
      tooFarMessage: '¡Vaya, estás tan lejos!',
      addNewAddress: 'Agregar nueva dirección',
      whatYourAddress: '¿Cuál es su dirección?',
      addressNotFound: 'Dirección no encontrada',
      confirmingAddress: 'Confirmando dirección',
      selectAnAddress: 'Seleccione una dirección',
      dontHaveZipcode: 'No conozco mi código postal',
      neighborhoodNotFound: 'Ninguno barrio encontrado',
      streetNameLabel: 'Calle, avenida, plaza, callejón',
      noAddressAdded: 'Aún no se han agregado direcciones',
      zipcodeNotFound: '$t(address.zipcode) no encontrado',
      cityPlaceholder: 'Introduzca el nombre de la ciudad',
      deliveryTimeAndTax: 'Ver tiempo de entrega y tarifa',
      fetchCityError: 'Error al buscar sugerencias de ciudades',
      selectAddressToDelivery: 'Seleccione una dirección de entrega',
      tooFarDescription: 'Dirección fuera de nuestra zona de entrega',
      streetNameOrZipcode: 'Nombre de la calle o $t(address.zipcode)',
      selectAnAddressToDelivery: '$t(address.selectAnAddress) de entrega',
      selectAnAddressDescription: 'para ver el tiempo de entrega y la tarifa',
      enterStreetNameOrZipcode: 'Introduzca el $t(address.streetNameOrZipcode)',
      addressNotFoundContactStore: 'Dirección no encontrada. ¡Contacta con la tienda!',
      addAddressMessage: 'Añada una dirección para ver los plazos y tarifas de entrega',
      fetchAddressError:
        'Estamos teniendo dificultades para encontrar su $t(address.zipcode), ingrese los datos manualmente',
      editAndRemoveInstructions:
        'Desliza una dirección (derecha para editar o izquierda para eliminar) o agrega una nueva dirección.'
    },
    myTab: {
      available: 'disponible',
      howToPay: '¿Cómo pagarás?',
      closedTable: 'Mesa cerrada',
      amountToPay: 'Monto a pagar',
      accountPaid: 'Cuenta pagada',
      payMyBill: 'Pagar mi factura',
      availablePlural: 'disponible',
      valueToPay: 'Pagar {{value}}',
      leaveReview: 'Deja una reseña',
      totalTable: 'Total de la mesa',
      enterValue: 'Introducir valor',
      billClosing: 'Cierre de cuenta',
      totalOf: 'Total de la {{mode}}',
      missingToPay: 'Queda por pagar',
      requestBill: 'Solicitar cuenta',
      requestedBill: 'Cuenta solicitada',
      summaryValues: 'Resumen de valores',
      accountSummary: 'Resumen de cuenta',
      paymentViaDashboard: 'Pago vía panel',
      pricePerPerson: 'Precio por persona:',
      subtotalOf: 'Subtotal de la {{mode}}',
      waitingForPayment: 'En espera de pago',
      payTotalTable: 'Paga la mesa completa',
      divideByPeaople: 'Dividir por personas',
      dontLikeToLeaveReview: 'No quiero evaluar',
      payRemainingValue: 'Pagar el monto restante',
      notOrderedYet: 'Aún no has realizado un orden',
      noOrderSoFar: 'Aún no se han realizado pedidos',
      bringUpBill: 'Un asistente le traerá su factura',
      processingAccount: 'Estamos procesando su cuenta, espere',
      billWasPaid: 'La factura ha sido pagada y su lista está vacía.',
      backToAccountTryAgain: 'Vuelve a tu cuenta e inténtalo de nuevo.',
      anotherLookInMenu: '¿Qué tal si echas otro vistazo a nuestro menú?',
      itemsWillAppearHere: 'Haz un orden y tus artículos aparecerán aquí',
      singleAmount: 'El monto único no puede ser mayor que el monto restante.',
      checkboxServiceTax: 'Casilla de verificación para el cargo por servicio',
      callAnAttendantToCloseBill: 'Para cerrar la cuenta, llame a un asistente',
      checkboxServiceCharge: 'Casilla de verificación para el cargo por servicio',
      likeToLeaveReview: '¿Quieres dejar una reseña sobre nuestro establecimiento?',
      howMuchYouWantToPay: 'Ingrese cuánto desea pagar de la factura total de esa mesa',
      makeNewPayment: 'Se realizó un pago reciente en esta mesa. ¿Quieres realizar un nuevo pago?',
      reopenAccount: 'La factura total fue pagada y cerrada. Para reabrirlo, realice un nuevo orden',
      usePixCodeInYourApp:
        'Copie el código abajo y use <strong>Pix Copiar y Pegar</strong> en su aplicación de pago. Espere hasta que <strong>se muestre el recibo</strong> que confirma su pago.'
    },
    order: {
      and: 'y',
      between: 'entre',
      viewOrder: 'Ver orden',
      prepare: 'Preparación',
      lastOrder: 'Último orden',
      orderReady: '¡Pedido listo!',
      tryAgain: 'Ordene nuevamente',
      cancelOrder: 'Cancelar orden',
      orderSent: '¡Pedido enviado!',
      sendOrder: 'Envío de pedidos',
      orderExpired: 'Orden caducada',
      sendingOrder: 'Enviando orden',
      payingOrder: 'Realizar el pago',
      confirmOrder: 'Confirmar orden',
      orderPending: 'Orden pendiente',
      cancelingOrder: 'Cancelar orden',
      orderCanceled: 'Orden cancelada',
      paymentReceived: 'Pago recibido',
      paymentPending: 'Pago pendiente',
      sendYourOrder: 'enviar tu pedido',
      inPreparation: '¡En preparación!',
      willBeDelivered: 'será entregado:',
      orderDetail: 'Detalles del pedido',
      orderConfirmed: 'Pedido confirmado',
      addMoreItem: 'Agregar más elementos',
      orderStatus: 'Seguimiento de pedidos',
      sendViaWhatsapp: 'Enviar por WhatsApp',
      scheduleReady: '¡Todo listo! {{ready}}',
      orderTracking: 'Seguimiento de pedidos',
      payAndMakeOrder: 'Paga y haz el pedido',
      mercadoPagoLink: 'Link de Mercado Pago',
      waitingPayment: 'Orden pendiente de pago',
      myOrder: 'Mi $t(general.order, lowercase)',
      scheduleOrderTime: 'El orden {{available}}',
      successIllustration: 'Ilustración de éxito',
      receiveNotifications: 'Recibir notificaciones',
      orderPreparing: 'El pedido está en preparación.',
      orderCanBeTaken: '¡El pedido ya se puede recoger!',
      orderTotalValue: 'Valor total del orden: {{value}}',
      cancelOrderQuestion: '¿Quieres cancelar todo el orden?',
      watchAnimation: 'Animación de un reloj haciendo tictac.',
      orderWentOutForDelivery: '¡El pedido ya está para entrega!',
      restaurantCanceledOrder: 'El restaurante canceló tu pedido.',
      scheduledDelivery: '¡Su entrega ha sido programada con éxito!',
      redirectingToSafeEnvironment: 'Redirigiendo al entorno seguro',
      scheduledTakeaway: '¡Su recogida ha sido programada con éxito!',
      checkOrderDetails: 'Vea abajo los detalles de su último orden.',
      waitingStoreConfirmation: 'Esperando confirmación de la tienda',
      statusWillAppearHere: 'Haga su pedido y siga su evolución aquí',
      cancelOrderWarning: 'Al cancelar, se perderá todo su progreso.',
      noOrderStatusAvailable: 'No hay pedidos en curso por el momento',
      willBeAvailableToTakeaway: 'estará disponible para ser recogido:',
      minimumValueNotMet: 'Valor informado inferior al valor del orden.',
      problemSendingOrder: '¿Problemas para enviar el orden vía WhatsApp?',
      acceptedByStoreFeedback: '¡Feedback de pedido aceptado por la tienda!',
      sorryForInconvenience: 'Lo siento por los inconvenientes ocasionados.',
      expectedDelivery: 'Previsión de entrega <strong>{{waitingTime}}</strong>',
      expectedTakeaway: 'Previsión de recogida <strong>{{waitingTime}}</strong>',
      whatsappUpdatesMessage: '¡Recibirás actualizaciones sobre tu orden vía WhatsApp!',
      zeroedOrderMessage: 'El valor del orden debe ser mayor que el subtotal {{value}} para continuar.',
      valueSmallerThanLoyalty:
        'El valor del subtotal del orden {{value}} debe ser mayor que el descuento por fidelidad.',
      cannotConfirmOrderInfo:
        'No pudimos confirmar los detalles de su orden. Contacta con el restaurante para agilizar tu servicio.'
    },
    errorMessage: {
      invalidCoupon: 'Cupón no válido.',
      expiredCoupon: 'El cupón utilizado ha caducado.',
      checkCvvCardCode: 'Comprobar el código de seguridad',
      checkTheCardNumber: 'Comprobar el número de tarjeta',
      invalidCouponHour: 'Cupón no válido por el momento.',
      pleaseChoosePaymentMethod: 'Elija una forma de pago.',
      checkCardDetails: 'Comprobar los datos de la tarjeta.',
      tabInfoError: 'Erro al buscar información de la comanda.',
      invalidProductsInOrder: 'Productos no válidos en el pedido.',
      checkExpiryDate: 'Compruebe la fecha de caducidad de la tarjeta',
      orderRejectedByMercadoPago: 'Orden rechazada por Mercado Pago..',
      couponDisabledByStore: 'Este cupón ha sido desactivado por la tienda.',
      invalidCouponForThisOrder: 'Cupón no válido para el valor de este pedido',
      errorToSaveAddress: 'Error al guardar la dirección utilizada en este pedido.',
      storeNotAcceptingWithoutSchedule: 'Esta tienda no acepta pedidos sin horario.',
      amountOfChange: 'El importe del cambio no puede ser inferior al valor total del pedido',
      errorToSendOrder: 'Algo ha ido mal al enviar el pedido. Por favor, actualice la página!',
      errorToFindLocation: 'Ha ocurrido un error al buscar una ubicación, inténtelo de nuevo.',
      errorToCloseBill: 'Algo ha ido mal al cerrar tu factura. Por favor, inténtelo de nuevo.',
      checkYourPhoneNumber: 'Comprueba que tu número de móvil es correcto e inténtalo de nuevo.',
      minimumPixOrderValue: 'El pedido mínimo con Pix es de R$ 20,00. Elija otro método de pago.',
      errorToGetBill: 'Ha ocurrido un error al buscar su factura. Por favor, inténtelo de nuevo.',
      storeIdNotFound: 'No hemos podido encontrar el ID de la tienda. Por favor, inténtelo de nuevo.',
      errorToGenerateMercadoPagoLink: 'Algo ha ido mal al generar el enlace de pago con Mercado Pago',
      errorToMakePayment: 'Algo ha ido mal al realizar el pago. Por favor, vuelva a cargar la página.',
      belowMinimumValue: 'El pedido está por debajo del precio mínimo de entrega para este restaurante.',
      notBeGenerated: 'No se ha podido generar el enlace de pago. Por favor, elija otro método de pago.',
      mercadoPagoMinimumValue: 'El valor de las transacciones con Mercado Pago debe ser de al menos R$ 0,50',
      errorToSendOrderCheckData: 'Algo ha ido mal al enviar el pedido. Comprueba los datos e inténtalo de nuevo.',
      errorGettingTableInfo: 'Algo ha ido mal al buscar la información de la tabla. Por favor, inténtelo de nuevo.',
      tableNumberNotFound: 'No se ha encontrado una tabla con este número. Compruebe el número e inténtelo de nuevo.',
      searchingDeliveryFee: 'Se ha producido un error al buscar la tarifa de entrega. Por favor, inténtalo de nuevo.',
      errorToGetOptionals: 'Algo salió mal al buscar opciones de productos. Por favor, inténtelo de nuevo. (Error #10)',
      browserDoesntSupportGeolocation:
        'Su navegador no soporta geolocalización, por favor inténtelo de nuevo en otro navegador.',
      errorToCheckYourLogin:
        'Algo ha ido mal al comprobar su inicio de sesión a través de {{loginOptionMode}}. Inténtelo de nuevo.',
      yourBillHasBeenRequested:
        'No hemos podido enviar la solicitud porque su factura ya ha sido solicitada. Hable con un asistente.',
      emailConfirmationExceeded:
        'Número máximo de confirmaciones por correo electrónico superado, inténtelo de nuevo en unos segundos.',
      errorToGenerateLink:
        'Algo salió mal al generar el enlace de Mercado Pago, por favor inténtelo de nuevo o utilice otro método de pago',
      errorToSendOrderWithSchedule:
        'Algo ha ido mal al enviar el pedido debido a la hora de la cita, por favor seleccione de nuevo la hora',
      checkCardDetailsContactStore:
        'Compruebe los datos de la tarjeta facilitados, si el problema persiste póngase en contacto con nosotros.',
      notAllowDirectPayment:
        'La entrega con tarifa a convenir no permite el pago directo a través de la App. Por favor, elija otro método de pago.',
      checkTheData:
        'Algo ha ido mal al enviar el pedido, por favor compruebe los datos e inténtelo de nuevo. Si el error persiste, ponte en contacto con la tienda.'
    },
    general: {
      or: 'o',
      to: '1',
      and: 'y',
      from: 'de',
      cpf: 'CPF',
      view: 'Ver',
      days: 'días',
      today: 'Hoy',
      type: 'Tipo',
      key: 'Clave',
      cnpj: 'CNPJ',
      new: 'Nuevo',
      table: 'Mesa',
      help: 'Ayuda',
      exit: 'Salir',
      daySuffix: '',
      copy: 'Copiar',
      add: 'Agregar',
      order: 'Orden',
      total: 'Total',
      back: 'Volver',
      free: 'Gratis',
      edit: 'Editar',
      tab: 'Comando',
      skip: 'Saltar',
      paid: 'Pagado',
      name: 'Nombre',
      open: 'Abierto',
      payment: 'Pago',
      coupon: 'Cupón',
      email: 'E-mail',
      close: 'Cerrar',
      rules: 'Normas',
      card: 'Tarjeta',
      price: 'Precio',
      google: 'Google',
      remove: 'Borrar',
      signIn: 'Entrar',
      number: 'Número',
      gotIt: 'Entendí',
      greeting: 'Hola',
      closed: 'Cerrado',
      balcony: 'Balcón',
      again: 'de nuevo',
      phone: 'Teléfono',
      qrCode: 'QR Code',
      items: 'Elementos',
      cancel: 'Cancelar',
      later: 'Más tarde',
      receipt: 'Pruebas',
      showMore: 'Ver más',
      showAll: 'Ver todo',
      signUp: 'Registrar',
      sending: 'Enviando',
      product: 'Producto',
      removing: 'Borrando',
      loyalty: 'Fidelidad',
      optional: 'Opcional',
      confirm: 'Confirmar',
      address: 'Dirección',
      invalid: 'No válido',
      change: 'Reemplazar',
      showLess: 'Ver menos',
      continue: 'Continuar',
      category: 'Categoría',
      discount: 'Descuento',
      inOrder: ' en la nota',
      myAccount: 'Mi cuenta',
      consumption: 'Consumo',
      yesRemove: 'Si, borrar',
      noThanks: 'No, gracias',
      okThanks: 'Ok, gracias',
      confirmed: 'Confirmado',
      required: 'Obligatorio',
      subscription: 'Registro',
      typeHere: 'Escribe aquí',
      categories: 'Categorías',
      yesCancel: 'Sí, cancelar',
      copyCode: 'Copiar código',
      subtotal: 'Total parcial',
      scheduling: 'Programación',
      firstOrder: 'Primer orden',
      unavailable: 'Indisponible',
      seeDetails: 'Ver detalles',
      everyDay: 'Todos los dias',
      validUntil: 'Válido hasta',
      reviewData: 'Revisar datos',
      editItem: 'Editar elemento',
      takeaway: 'Rrecogida local',
      okayLetsGo: '¡Ok, vámonos!',
      onTheBalcony: 'En el balcón',
      backToMenu: 'Volver al menú',
      confirmation: 'Confirmación',
      tryAgain: 'Intentar otra vez',
      observations: 'Observaciones',
      saveChanges: 'Guardar cambios',
      invalidField: 'Campo inválido',
      invalidDate: 'Fecha no válida',
      okayGotIt: '¡Ok, lo entiendo!',
      viewOnly: 'Solo visualización',
      removeItem: 'Eliminar elemento',
      principalMenu: 'Menú principal',
      loadingImage: 'Cargando imagen',
      waitMoment: 'Espera un momento',
      anExperience: 'Una experiencia',
      invalidValue: 'Valor no válido',
      stillToBePaid: 'Necesito pagar',
      chooseOption: 'Elige una opción',
      totalWithTax: 'Total con tarifa',
      howDoesItWork: '¿Cómo funciona?',
      deliveryTax: 'Tarifa de entrega',
      serviceTax: 'Tarifa de servicio',
      invalidEmail: 'E-mail no válido!',
      startChat: 'Iniciar conversación',
      chooseOneOption: 'Elige 1 opción',
      orderSummary: 'Resumen del pedido',
      workingHours: 'Horario de apertura',
      otherCategories: 'Otras categorias',
      birthdayDate: 'Fecha de nacimiento',
      emailExample: 'nombre@email.com.br',
      subtotalCoupon: 'Subtotal del cupón',
      addObservation: 'Añadir observación',
      discountOfType: 'Descuento {{type}}',
      houseSuggestion: 'Sugerencia de casa',
      totalWithDiscount: 'Total descontado',
      reportAProblem: 'Informar un problema',
      contactStore: 'Contacta con la tienda',
      subtotalDiscount: 'Descuento subtotal',
      documentInOrder: '{{document}} en nota: ',
      successfullyCopied: '¡Copiado con éxito!',
      firstNameAndLastName: 'Nombre y apellido',
      myOrder: 'Mi $t(general.order, lowercase)',
      insertYourName: 'Introduzca el nombre aquí',
      bitFarAway: 'Parece que estás un poco lejos',
      insertDocument: 'Introduzca aquí su CPF/CNPJ',
      paymentWays: 'Métodos de $t(general.payment)',
      subtotalWithDiscount: 'Subtotal con descuento',
      itemAddedToCart: 'Artículo agregado a la bolsa',
      paymentMethods: 'Maneras de $t(general.payment)',
      enterAndParticipate: 'Inicia sesión y participa',
      paymentConfirmed: '$t(general.payment) confirmado',
      discountForTakeaway: 'Descuento por recogida local',
      enterYourPhoneNumber: 'Ingresa tu número de celular',
      noProductsRegistered: 'No hay productos registrados',
      optionsLimitReached: '¡Límite de opciones alcanzado!',
      cancelPayment: 'Cancelar $t(general.payment, lowercase)',
      invalidBirthdayDate: '$t(general.birthdayDate) inválida',
      enterYourEmail: 'Informe tu $t(general.email, lowercase)',
      namePlaceholder: 'Informe tu $t(general.name, lowercase)',
      orderDetails: 'Detalles del $t(general.order, lowercase)',
      emailPlaceholder: 'Informe tu $t(general.email, lowercase)',
      earnDiscount: '¡{{discountPercentage}}% descuento!',
      shouldInsertDocument: 'Necesitas informar tu {{- document}}',
      successConfigMessage: '¡La configuración cambió exitosamente!',
      newsSoon: '¡Pero pronto tendremos noticias increíbles para ti!',
      invalidDocument: '{{- document}} $t(general.invalid, lowercase)',
      paymentConfirm: 'Confirmación de $t(general.payment, lowercase)',
      paymentNotFinished: '$t(general.payment, lowercase) no realizado',
      subtotalWithLoyaltyDiscount: 'Subtotal con descuento por fidelidad',
      changePaymentMethod: 'Cambiar método de $t(general.payment, lowercase)',
      enterValidPhoneNumber: 'Proporcione un número de teléfono celular válido',
      enterYourNameAndLastName: 'Ingrese su nombre y apellido (sin abreviaturas)',
      contactStoreForHelp: 'Comuníquese con la tienda para resolver este problema.',
      observationPlaceholder: 'Introduzca aquí sus $t(general.observations, lowercase)...',
      toUseTheApp: 'Para utilizar la aplicación es necesario estar cerca del establecimiento',
      freeDeliveryMinimumValue: '<strong>Entrega gratuita</strong> en pedidos superiores a {{value}}!',
      whatsappSendReminder: 'Recuerda enviar tu orden vía WhatsApp al restaurante. ¡Es fácil y rápido!',
      minimumValue: 'Orden <strong>mínimo de {{value}}</strong> para delivery. Gastos de envío no incluidos.',
      observationMessage:
        'Habla directamente con el establecimiento si quieres modificar algún artículo. En este campo no se aceptan modificaciones que puedan generar cargos adicionales.'
    },
    payment: {
      money: 'Dinero',
      debit: 'Débito',
      credit: 'Crédito',
      pixKey: 'Llave Pix',
      byNubank: 'vía Nubank',
      securityCardCode: 'CVV',
      newPayment: 'Nuevo pago',
      expiryDate: 'Expiración',
      cancelDelete: 'No borrar',
      byIntegratedPix: 'vía Pix',
      addCard: 'Agregar tarjeta',
      useCard: 'Utilice tarjeta',
      mercadoPagoQrCode: 'QRCode',
      mercadoPago: 'Mercado Pago',
      byApplePay: 'vía Apple Pay',
      byGooglePay: 'vía Google Pay',
      needChange: 'Necesito cambio',
      showQrCode: 'Mostrar QR Code',
      mealVoucher: 'Vale de Comida',
      minimumOrder: 'Pedido mínimo',
      chooseFlag: 'Elige la bandera',
      expiryDatePlaceholder: 'MM/AA',
      howPay: '¿Cómo quieres pagar?',
      cardNumber: 'Número de tarjeta',
      cardType: 'Tarjeta de {{type}}',
      creditCard: 'Tarjeta de crédito',
      paymentMethods: 'Métodos de pago',
      changeFor: 'Cambio por: {{value}}',
      addNewCard: 'Agregar nueva tarjeta',
      askForChange: '¿Necesitas un cambio?',
      makeNewPayment: 'Realizar nuevo pago',
      deleteCard: 'Eliminar tarjeta de {{type}}?',
      onlinePaymentLink: 'Enlace de pago en línea',
      paymentLink: 'Enlace de $t(general.payment)',
      qrCodePaymentCode: 'Código para pago QR Code',
      changePaymentMethod: 'Cambiar método de pago',
      showPixCopyAndPaste: 'Ver Pix Copiar y Pegar',
      onlinePayment: '$t(general.payment) en línea',
      choosePaymentMethod: 'Elige el método de pago',
      invalidNumber: '¡Número no válido o incompleto!',
      cardholderName: 'Nombre del titular de la tarjeta',
      deliveryPayment: '$t(general.payment) contra entrega',
      balconyPayment: '$t(general.payment) en el mostrador',
      cardholderDocument: 'CPF/CNPJ del titular de la tarjeta',
      errorDuringPayment: 'Se produjo un error durante el pago',
      cannotChoosePayment: 'No se puede elegir un método de pago',
      documentOnSlip: '{{documentType}} en la nota: {{document}}',
      cardDeleteWarningMessage: 'Esta acción no se puede deshacer',
      orderTimeLimitReached: 'El plazo para pagar el orden ha expirado.',
      goBackToOrder: 'Regrese al menú si desea realizar un nuevo orden.',
      processingPaymentAndSendOrder: 'Procesando pago y enviando pedido...',
      pleaseReviewYourData: 'Revise sus datos de pago e inténtelo de nuevo.',
      stepWarning: 'No abandone la pantalla hasta que se complete el {{step}}.',
      invalidSecurityCardCode: '¡Número $t(payment.securityCardCode) no válido!',
      verifyPaymentMethod: 'Verifique su método de pago e inténtelo nuevamente.',
      nuPayBillingMessage: 'Enviaremos un cargo a Nubank con los importes de este orden.',
      pageRefreshMessage: 'Si ya realizó el pago, actualice la página para ver la confirmación.',
      paymentReceiptReminder: 'No olvides enviar el recibo al restaurante para acreditar el pago.',
      orderCancelSuccess: '<strong>¡El orden fue cancelado exitosamente!</strong> No se cobró nada.',
      autoCancelOrderMessage: 'El orden será cancelado automáticamente si el pago no se realiza a tiempo.',
      choosePaymentHelp: 'Hola, tengo problemas para elegir un método de pago para mi orden. ¿Me puedes ayudar?',
      paymentLinkViaWhatsappMessage:
        '¡Te enviaremos el enlace de pago vía WhatsApp tan pronto como se confirme tu orden!',
      applePayInfo:
        '<strong>Apple Pay</strong> es una forma <strong>fácil</strong> y <strong>segura</strong> de pagar tus pedidos!',
      scanQrCodeMessage:
        'Escanea el QR Code que te enviaremos con tu pedido. Utiliza las aplicaciones Mercado Pago o Mercado Livre.',
      payThroughThePix:
        'Copie la llave y realice el pago a través de Pix. El restaurante comprobará el pago para liberar su pedido.',
      googlePayInfo:
        'Pago <strong>rápido, fácil</strong> y <strong>seguro</strong> con <strong>Google Pay</strong>. Pruébelo ahora!',
      mpScanQrCodeMessage:
        'Escanea el QR Code que te enviaremos con tu orden. Utiliza las aplicaciones Mercado Pago o Mercado Livre.',
      paymentNotConfirmedMessage:
        'No pudimos confirmar su pago. Por razones de seguridad, cancelamos el orden. Si ha realizado el pago, solicite un reembolso al restaurante.',
      errors: {
        commonErrors: 'Vea los principales motivos que pueden haber provocado un error en su pago',
        emailMustBeDifferent:
          'El e-mail utilizado para el pago no puede ser el mismo e-mail que la cuenta de la tienda.'
      },
      nupay: {
        orderByNupay: 'Realice el pago a través de la aplicación Nubank',
        redirectToApp:
          'Si no aparece ninguna notificación, toque el botón a continuación para ser dirigido a la aplicación Nubank',
        requirements:
          'Para utilizar este método de pago, debe ser cliente de Nubank y tener un límite de crédito o saldo de cuenta.',
        finishedOrderMessage:
          'Haga clic en la notificación de Nubank que acabamos de enviar para finalizar su compra, elija pagar en efectivo o a plazos sin intereses y confirme el pago.'
      },
      methods: {
        pix: 'Pix',
        cash: 'Dinero',
        debit: 'Débito',
        nupay: 'Nubank',
        credit: 'Crédito',
        voucher: 'Voucher',
        vrPague: 'VR Pague',
        applePay: 'Apple Pay',
        googlePay: 'Google Pay',
        mPagoCheckout: 'Mercado Pago',
        mealVoucher: 'Vale de Comida',
        mPagoQr: 'QR Code Mercado Pago',
        onApp: 'Pagar por la aplicación',
        onDelivery: 'Paga contra entrega',
        mPagoLink: 'Enlace de Mercado Pago',
        onAttendant: 'Pagar por un asistente',
        vrPagueLink: 'Enlace de pago VR Pague',
        noCreditCardRegistered: 'Ninguna tarjeta de crédito registrada',
        noPaymentCreditCard: 'Este establecimiento no dispone de ninguna forma de pago con tarjeta de crédito.'
      },
      howItWorks: {
        success: '¡Listo! La confirmación del pago realizado aparecerá en tu pantalla.',
        mpQrCode: {
          description:
            'Abre la app de Mercado Pago y escanea el QR Code que será enviado (delivery) o disponible en nuestra tienda (recogida local).'
        },
        mpPaymentLink: {
          secure: 'El entorno de pago es 100% seguro. Utilice su tarjeta de crédito para pagar.',
          description:
            'Recibirá un enlace una vez que envíe su orden. ¡Simplemente haz clic e ingresa a la pantalla de pago!'
        },
        nupay: {
          finishedInApp: 'La compra se completará en la aplicación Nubank',
          noCardInfoNeeded: 'No es necesario que rellenes los datos de tu tarjeta',
          noInterest: 'Paga tu compra en cuotas sin intereses o paga en efectivo vía débito',
          secure: 'Puedes confiar, es seguro. Tu compra está protegida por nuestras medidas de seguridad.'
        }
      },
      cardErrorList: [
        {
          title: 'Nueva tarjeta bloqueada',
          content: 'Si es una tarjeta nueva, contacta con el banco para desbloquearla.'
        },
        {
          title: 'Tarjeta bloqueada para transacciones en línea',
          content: 'Póngase en contacto con su banco para desbloquear su tarjeta y realizar transacciones en línea.'
        },
        {
          title: 'Transacción no aprobada por el banco',
          content: 'Comuníquese con su banco para comprender el motivo de la transacción no aprobada.'
        },
        {
          title: 'Tarjeta sin saldo',
          content: 'Comprueba si tu tarjeta tiene límite suficiente para realizar esta transacción.'
        },
        {
          title: 'Tarjeta caducada',
          content: 'Consulta la fecha de caducidad de la tarjeta o contacta con tu banco.'
        },
        {
          title: 'Código de seguridad no válido',
          content: 'Verifique el código de seguridad de su tarjeta (CVV) ubicado en el reverso.'
        }
      ],
      pix: {
        pixPayment: 'Pago Pix',
        expired: 'Pix caducado',
        codeExpired: 'Código Pix caducado',
        copyAndPaste: 'Pix Copiar y Pegar',
        codeExpiresIn: 'Este código caduca en:',
        touchToCopy: 'Toca para copiar el código',
        expiredPixCode: 'Tu código de foto ha caducado',
        keyCopied: '¡La clave Pix se copió correctamente!',
        codeCopied: '¡El código Pix se copió correctamente!',
        countdown: 'temporizador de cuenta regresiva para la expiración del código Pix',
        receiptMessage: 'Le enviaremos el comprobante del orden a su e-mail tan pronto como se apruebe el pago.',
        confirmPaymentHelpMessage:
          'Hola, me gustaría confirmar la recepción de mi orden a través de Pix. ¿Me puedes ayudar?',
        copyAndPayMessage:
          'Copie el código abajo, pague a través de la aplicación del banco y envíe el recibo al restaurante.',
        orderCancelSuccess:
          '¡El orden fue cancelado exitosamente! No se cobró nada. Regrese al menú si desea realizar un nuevo orden.',
        copyAndPasteMessage:
          'Copie el código a continuación y use Pix Copiar y Pegar en la aplicación de pago de su elección. Tu orden será enviado automáticamente al restaurante.',
        cancelOrderMessage:
          'El orden será cancelado automáticamente si no se realiza el pago dentro del plazo indicado. Si ya has realizado el pago, intenta recargar esta pantalla.'
      }
    }
  }
};
