var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"DmViD8PpfhX-H0bUmUfJo"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,

  integrations: [Sentry.replayIntegration({
    blockAllMedia: false, // block all media elements (img, svg, video, object, picture, embed, map, audio)

    maskAllText: false,
    maskAllInputs: false,
    mask: ['.sentry-mask', '[data-sentry-mask]'],
    block: ['.sentry-block', '[data-sentry-block]'],
    ignore: ['.sentry-ignore', '[data-sentry-ignore]']
  })],

  attachStacktrace: true, // when enabled, stack traces are automatically attached to all messages logged

  tracesSampleRate: 0.5, // controlling the percentage chance a given transaction will be sent
  replaysSessionSampleRate: 0.2, // sample rate for replays that begin recording immediately and last the entirety of the user's session
  replaysOnErrorSampleRate: 1.0, // sample rate for replays that are recorded when an error happens
});
